import tableRendererWrapper from '@shared/ui/display/Table/tableRendererWrapper';
import { SUBSCRIPTION_PLANS } from '@entities/subscription';
import { SubscriptionPlatformType } from '@shared/api/subscription';
import DateCell from '../components/DateCell';
import AmountCell from '../components/AmountCell';
import PaymentStatus from '../components/PaymentStatus';
import SubscriptionPlanCell from '../components/SubscriptionPlanCell';
import ActionButtonCell from '../components/ActionButtonCell';
const getPaymentColumnsDefinition = (t) => ([
    {
        header: t('settings.tabs.subscription.payment_table.date'),
        accessorKey: 'date',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        aggregationFn: 'count',
        cell: tableRendererWrapper(DateCell),
    },
    {
        header: t('settings.tabs.subscription.payment_table.total'),
        accessorKey: 'total',
        enableSorting: false,
        meta: {
            flex: 1,
            align: 'center',
        },
        cell: tableRendererWrapper(AmountCell),
    },
    {
        header: t('settings.tabs.subscription.payment_table.platform_fee'),
        accessorKey: 'platformFee',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: tableRendererWrapper(AmountCell),
    },
    {
        header: t('settings.tabs.subscription.payment_table.status'),
        accessorKey: 'status',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: tableRendererWrapper(PaymentStatus),
    },
    {
        header: t('settings.tabs.subscription.payment_table.subscription_plan'),
        accessorKey: 'subscriptionPlan',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: tableRendererWrapper(SubscriptionPlanCell),
    },
    {
        header: '',
        accessorKey: 'action_btn',
        enableSorting: false,
        meta: {
            flex: 1,
            align: 'right',
        },
        cell: tableRendererWrapper(ActionButtonCell),
    },
]);
const sortByPrice = (firstPlan, secondPlan) => (firstPlan.fee < secondPlan.fee ? -1 : 1);
// temporary func(would be removed when we would recieve starter plan from backend)
// at current moment we should check ourself if we need to add starter plan in list
const definePlansList = (subscriptionPlansList, currentPlan) => {
    let hasStarterPlan = false;
    const plansList = subscriptionPlansList.map((plan) => {
        if (plan.name === SubscriptionPlatformType.Starter) {
            hasStarterPlan = true;
            plan.fee = 0;
        }
        if (plan.name) {
            if (plan.name === currentPlan.name) {
                return Object.assign(Object.assign({}, plan), { id: currentPlan.id, isSelected: true });
            }
        }
        return plan;
    });
    const result = hasStarterPlan ?
        plansList.sort(sortByPrice) :
        [SUBSCRIPTION_PLANS.STARTER, ...plansList].sort(sortByPrice);
    return result;
};
export const getPlansList = ({ currentPlan, subscriptionPlansList, }) => {
    if (currentPlan && subscriptionPlansList) {
        return currentPlan.isInList ?
            definePlansList(subscriptionPlansList, currentPlan) :
            [...(subscriptionPlansList || []), currentPlan].sort(sortByPrice);
    }
    if (subscriptionPlansList) {
        return subscriptionPlansList;
    }
    return [];
};
export default getPaymentColumnsDefinition;
