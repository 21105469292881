import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material';
import { ContainerRow } from '@src/components/styled';
import { defineCurrentSubscription, mapSubscriptionPlans } from '@entities/subscription';
import { useGetCurrentSubscriptionPlansQuery, useGetSubscriptionPlansQuery } from '@shared/api/subscription';
import { getPlansList } from '@pages/settings-subscription/lib';
import { useAppSelector } from '@store/Hooks';
import Block from '@shared/ui/settings/BlockContainer';
import { ChangeSubscriptionStrategy, openPaymentMethodForm, PaymentMethodFormType } from '@entities/current-user';
import { checkPlanFee } from '@pages/settings-subscription/components/SubscriptionPlanList/utils';
import SkeletonGroup from '@shared/ui/progress-loaders/SkeletonGroup';
import SubscriptionPlanInfo from './SubscriptionPlanInfo';
const PlanListContainer = styled(ContainerRow)((props) => ({
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    gap: props.theme.spacing_sizes.l,
}));
const PlanListSkeletonGroup = styled(SkeletonGroup)((props) => ({
    display: 'flex',
    flexDirection: 'row',
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.l,
}));
const SubscriptionPlanList = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { user } = useAppSelector(state => state.user);
    const { data: currentSubscriptionData, isLoading: currentSubscriptionDataIsLoading, } = useGetCurrentSubscriptionPlansQuery({
        userId: (user === null || user === void 0 ? void 0 : user.id) || '',
    }, {
        skip: !user,
    });
    const { data: subscriptionPlansData, isLoading: subscriptionPlansDataIsLoading, } = useGetSubscriptionPlansQuery();
    const isLoading = currentSubscriptionDataIsLoading || subscriptionPlansDataIsLoading;
    const activeSubscription = useMemo(() => currentSubscriptionData ? defineCurrentSubscription(currentSubscriptionData) : undefined, [currentSubscriptionData]);
    // used as first descriptive column, also used as mock for isLoading state
    const planDescription = useMemo(() => ({
        title: '',
        description: '',
        options: {
            limits: t('settings.tabs.subscription.subscription_plan.copy_trading_limit'),
            spotCopy: t('settings.tabs.subscription.subscription_plan.spot_copy'),
            margin: t('settings.tabs.subscription.subscription_plan.margin'),
            futures: t('settings.tabs.subscription.subscription_plan.futures'),
            support: t('settings.tabs.subscription.subscription_plan.support'),
        },
        id: 'INFO',
        fee: 0,
    }), [t]);
    const subscriptionPlans = useMemo(() => subscriptionPlansData ? mapSubscriptionPlans(subscriptionPlansData) : [], [subscriptionPlansData]);
    const plansToDisplay = useMemo(() => {
        if (isLoading) {
            return [planDescription, planDescription, planDescription];
        }
        return getPlansList({ currentPlan: activeSubscription, subscriptionPlansList: subscriptionPlans });
    }, [
        activeSubscription,
        subscriptionPlans,
        planDescription,
        isLoading,
    ]);
    const handlePlanChange = (planId) => {
        const selectedPlan = subscriptionPlans.find(p => p.id === planId);
        const selectedPlanFee = checkPlanFee(selectedPlan);
        const currentPlanFee = checkPlanFee(activeSubscription);
        const isSelectedPriceHigher = typeof selectedPlanFee === 'number' && typeof currentPlanFee === 'number' &&
            selectedPlanFee > currentPlanFee;
        const isUpgrade = (activeSubscription === null || activeSubscription === void 0 ? void 0 : activeSubscription.isFree) || isSelectedPriceHigher;
        if (isUpgrade) {
            dispatch(openPaymentMethodForm({
                type: PaymentMethodFormType.ChangeSubscriptionPlan,
                strategy: ChangeSubscriptionStrategy.Upgrade,
                plan: selectedPlan,
            }));
        }
        else {
            dispatch(openPaymentMethodForm({
                type: PaymentMethodFormType.ChangeSubscriptionPlan,
                strategy: ChangeSubscriptionStrategy.Downgrade,
                plan: selectedPlan,
                currentPlan: activeSubscription,
            }));
        }
    };
    return (_jsx(Block, { title: t('settings.tabs.subscription.subscription_plan.title'), gap: theme.spacing_sizes.m, padding: theme.spacing_sizes.s * 5, children: _jsxs(PlanListContainer, { children: [_jsx(SubscriptionPlanInfo, { plan: planDescription, flex: 1 }, planDescription.id), _jsx(PlanListSkeletonGroup, { isLoading: isLoading, children: plansToDisplay.map((plan, idx) => (_jsx(SubscriptionPlanInfo, { plan: plan, flex: 2, isLoading: isLoading, isDescriptionContainer: true, onSelect: handlePlanChange }, idx))) })] }) }));
};
export default SubscriptionPlanList;
